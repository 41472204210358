<template>
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <b>{{$t('profile.title')}}</b>
            </div>

            <div class="subtitle-1 font-weight-light">
              {{$t('profile.complete')}}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field disabled
                    v-model="userModel.firstName"
                    :label="$t('labels.firstName')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field disabled
                    v-model="userModel.lastName"
                    :label="$t('labels.lastName')"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="userModel.email"
                    :label="$t('labels.email')"
                  />
                </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="locationModel.street"
                    :label="$t('labels.location.street')"
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="locationModel.number"
                    :label="$t('labels.location.housenumber')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="locationModel.postalCode"
                    :label="$t('labels.location.postalcode')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-text-field
                    v-model="locationModel.city"
                    :label="$t('labels.location.city')"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                    disabled
                    value="België"
                    :label="$t('labels.location.country')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="success"
                    class="mr-0"
                    @click="save"
                  >
                    {{$t('labels.confirm')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
        
</template>

<script>
  export default {
    data() {
      return {
        userModel: {},
        locationModel: {},
        initialLocation: null,
        loading: false
      }
    },
    async mounted() {
      this.locationModel = await this.$api.getUserLocation()
      this.initialLocation = JSON.parse(JSON.stringify(this.locationModel)) // cheap clone
      this.updateUserModel()
    },
    watch: {
      user (val, oldVal) {
        this.updateUserModel()
      }
    },
    computed: {
      user() { return this.$store.getters.user }
    },
    methods: {
      updateUserModel() {
        this.userModel = JSON.parse(JSON.stringify(this.user)) // cheap clone
      },
      async save() {
        try {

          if (this.locationModel.street != this.initialLocation.street
            || this.locationModel.number != this.initialLocation.number
            || this.locationModel.postalCode != this.initialLocation.postalCode
            || this.locationModel.city != this.initialLocation.city) {
              if (!this.locationModel.street || !this.locationModel.number || !this.locationModel.postalCode || !this.locationModel.city) {
                this.$showError(this.$t('labels.fillAllFields'))
                return;
              }

              this.loading = true
              await this.$api.changeAddress(this.locationModel)
          }

          this.loading = true

          if (this.userModel.email != this.user.email)
            await this.$api.changeEmail(this.userModel.email)

          // Reset the store
          this.$store.dispatch('loadUserProfile')
        } catch (e) {
          // http errors were shown to user
        }
        this.loading = false
      }
    }
  }
</script>
